<template>
  <article ref="observer" class="entry" :class="{ intersected }">
    <LazyAtomsHeading element="h4" class="entry-title">{{
      title
    }}</LazyAtomsHeading>
    <SanityContent
      :blocks="definitionFull"
      :serializers="useSerializers('simpleText')"
    />
  </article>
</template>

<script lang="ts" setup>
defineProps({
  title: String,
  definition: String,
  definitionFull: { type: Array, default: () => [] },
  terms: Array as () => string[],
})

const { observer, intersected } = useIntersectionObserver({
  rootMargin: '0px 0px -20% 0px',
  triggerOnce: true,
})
</script>

<style lang="scss">
.entry {
  position: relative;
  margin: 0 auto 10vh;
  max-width: $ch + ch;

  &.intersected > * {
    animation-play-state: running !important;
  }

  .entry {
    &-title {
    }
    &-definition {

    }
  }
}
</style>
